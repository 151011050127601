import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useChatContext } from "../../context/ChatContext";

const Sidebar = ({ onLinkClick }) => {
    const { user, hasRole } = useContext(AuthContext);
    const { unreadMessagesCount } = useChatContext();

    const linkClass =
        "text-white bg-sky-600 px-5 py-2 rounded hover:bg-sky-500 hover:text-white block mb-2 transition-colors duration-200 relative flex justify-between items-center";
    const subHeaderClass = "text-black bg-gray-300 px-5 py-1 rounded block mb-2";

    return (
        <>
            <Link className={linkClass} to="/oma-sivu" onClick={onLinkClick}>
                Oma tili
            </Link>
            <Link className={linkClass} to="/oma-sivu/viestit" onClick={onLinkClick}>
                Viestit
                {unreadMessagesCount > 0 && <span className="text-white">{unreadMessagesCount}</span>}
            </Link>
            <Link className={linkClass} to="/oma-sivu/ilmoitukset" onClick={onLinkClick}>
                Ilmoitukset
            </Link>
            <Link className={linkClass} to="/oma-sivu/kuvapankki" onClick={onLinkClick}>
                Kuvapankki
            </Link>
            {hasRole(user.roles, "ROLE_ADMIN") && (
                <>
                    <div className={subHeaderClass}>YLLÄPITÄJÄ</div>
                    <Link className={linkClass} to="/hallinta/ilmoitukset" onClick={onLinkClick}>
                        Ilmoitukset
                    </Link>
                    <Link className={linkClass} to="/hallinta/tarjoukset" onClick={onLinkClick}>
                        Tarjoukset
                    </Link>
                </>
            )}
            {hasRole(user.roles, "ROLE_SUPERADMIN") && (
                <>
                    <div className={subHeaderClass}>SUPERADMIN</div>
                    <Link className={linkClass} to="/hallinta/kayttajat" onClick={onLinkClick}>
                        Käyttäjät
                    </Link>
                    <Link className={linkClass} to="/hallinta/artikkelit" onClick={onLinkClick}>
                        Artikkelit
                    </Link>
                    <Link className={linkClass} to="/hallinta/kohteet" onClick={onLinkClick}>
                        Kohteet
                    </Link>
                    <Link className={linkClass} to="/hallinta/asetukset" onClick={onLinkClick}>
                        Asetukset
                    </Link>
                    <Link className={linkClass} to="/hallinta/alennuskoodit" onClick={onLinkClick}>
                        Alennuskoodit
                    </Link>
                    <Link className={linkClass} to="/hallinta/tuotteet" onClick={onLinkClick}>
                        Tuotteet
                    </Link>
                    <Link className={linkClass} to="/hallinta/yhteysviestit" onClick={onLinkClick}>
                        Yhteysviestit
                    </Link>
                </>
            )}
            {hasRole(user.roles, "ROLE_GOD") && (
                <>
                    <div className={subHeaderClass}>GOD</div>
                    <Link className={linkClass} to="/hallinta/toiminnot" onClick={onLinkClick}>
                        Toiminnot
                    </Link>
                </>
            )}
        </>
    );
};

export default Sidebar;
