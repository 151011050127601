import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ConfigProvider, Layout } from "antd";

import { AuthProvider } from "./context/AuthContext";
import { ModalProvider } from "./context/ModalContext";
import { ChatProvider } from "./context/ChatContext";

import ProtectedRoute from "./components/security/ProtectedRoute";

import TagManager from "react-gtm-module";

import FrontPageLayout from "./components/layouts/FrontpageLayout";
import MainLayout from "./components/layouts/MainLayout";
import AdminLayout from "./components/layouts/AdminLayout";

import Home from "./pages/Home";
import Login from "./pages/Login";
import AuctionPage from "./pages/AuctionPage";
import AuctionListPage from "./pages/AuctionListPage";

import Profile from "./pages/admin/Profile";
import Auctions from "./pages/admin/Auctions";
import UserAuctions from "./pages/admin/UserAuctions";
import Auction from "./pages/admin/Auction";
import Chats from "./pages/admin/Chats";
import Gallery from "./pages/admin/Gallery";
import Properties from "./pages/admin/Properties";
import PropertyEdit from "./pages/admin/PropertyEdit";
import Settings from "./pages/admin/Settings";
import AdminActions from "./pages/admin/AdminActions";
import Users from "./pages/admin/Users";
import ContactPage from "./pages/ContactPage";
import UserConfirmPage from "./pages/UserConfirmPage";
import FaqPage from "./pages/FaqPage";
import AuthReturn from "./components/security/AuthReturn";
import Contract from "./pages/admin/Contract";
import PasswordResetPage from "./pages/PasswordResetPage";
import NotFound from "./pages/NotFound";
import PaymentReturn from "./pages/admin/PaymentReturn";
import DiscountCodes from "./pages/admin/DiscountCodes";
import ContactMessagesPage from "./pages/admin/ContactMessagesPage";
import User from "./pages/admin/User";
import Products from "./pages/admin/Products";
import Blog from "./pages/admin/Blog";
import BlogEdit from "./pages/admin/BlogEdit";
import BlogPost from "./pages/BlogPost";
import Offers from "./pages/admin/Offers";

const App = () => {
    const routes = [
        {
            path: "/",
            element: (
                <FrontPageLayout>
                    <Home />
                </FrontPageLayout>
            ),
        },
        {
            path: "/404",
            element: (
                <MainLayout>
                    <NotFound />
                </MainLayout>
            ),
        },
        {
            path: "/kirjaudu",
            element: (
                <MainLayout>
                    <Login />
                </MainLayout>
            ),
        },
        {
            path: "/kohteet",
            element: (
                <MainLayout>
                    <AuctionListPage />
                </MainLayout>
            ),
        },
        {
            path: "/kohteet/:type",
            element: (
                <MainLayout>
                    <AuctionListPage />
                </MainLayout>
            ),
        },
        {
            path: "/kohde/:number",
            element: (
                <MainLayout>
                    <AuctionPage />
                </MainLayout>
            ),
        },
        {
            path: "/kohde/:number/:slug",
            element: (
                <MainLayout>
                    <AuctionPage />
                </MainLayout>
            ),
        },
        {
            path: "/yhteystiedot",
            element: (
                <MainLayout breadcrumbsVisible={false}>
                    <ContactPage />
                </MainLayout>
            ),
        },
        {
            path: "/ehdot-ja-ohjeet",
            element: (
                <MainLayout breadcrumbsVisible={false}>
                    <FaqPage />
                </MainLayout>
            ),
        },
        {
            path: "/ehdot-ja-ohjeet/:page",
            element: (
                <MainLayout breadcrumbsVisible={false}>
                    <FaqPage />
                </MainLayout>
            ),
        },
        {
            path: "/vahvista/:uuid",
            element: (
                <MainLayout>
                    <UserConfirmPage />
                </MainLayout>
            ),
        },
        {
            path: "/vaihda-salasana/:token",
            element: (
                <MainLayout>
                    <PasswordResetPage />
                </MainLayout>
            ),
        },
        {
            path: "/artikkelit",
            element: (
                <MainLayout>
                    <Blog />
                </MainLayout>
            ),
        },
        {
            path: "/artikkelit/:slug",
            element: (
                <MainLayout>
                    <BlogPost />
                </MainLayout>
            ),
        },
        {
            element: <ProtectedRoute requiredRole="ROLE_USER" />,
            children: [
                {
                    path: "/hallinta/ilmoitukset",
                    element: (
                        <AdminLayout>
                            <Auctions />
                        </AdminLayout>
                    ),
                },
            ],
        },
        {
            element: <ProtectedRoute requiredRole="ROLE_USER" />,
            children: [
                {
                    path: "/oma-sivu",
                    element: (
                        <AdminLayout>
                            <Profile />
                        </AdminLayout>
                    ),
                },
                {
                    path: "/oma-sivu/viestit",
                    element: (
                        <AdminLayout>
                            <Chats />
                        </AdminLayout>
                    ),
                },
                {
                    path: "/oma-sivu/viestit/:chatUuid",
                    element: (
                        <AdminLayout>
                            <Chats />
                        </AdminLayout>
                    ),
                },
                {
                    path: "/oma-sivu/ilmoitukset",
                    element: (
                        <AdminLayout>
                            <UserAuctions />
                        </AdminLayout>
                    ),
                },
                {
                    path: "/oma-sivu/ilmoitukset/:uuid",
                    element: (
                        <AdminLayout>
                            <Auction />
                        </AdminLayout>
                    ),
                },
                {
                    path: "/oma-sivu/sopimus/:uuid",
                    element: (
                        <AdminLayout>
                            <Contract />
                        </AdminLayout>
                    ),
                },
                {
                    path: "/oma-sivu/kuvapankki",
                    element: (
                        <AdminLayout>
                            <Gallery />
                        </AdminLayout>
                    ),
                },
                {
                    path: "/oma-sivu/kuvapankki/:folderId",
                    element: (
                        <AdminLayout>
                            <Gallery />
                        </AdminLayout>
                    ),
                },
                {
                    path: "/auth/return",
                    element: <AuthReturn />,
                },
                {
                    path: "/maksu/:result",
                    element: (
                        <AdminLayout sidebar={false}>
                            <PaymentReturn />
                        </AdminLayout>
                    ),
                },
            ],
        },
        {
            element: <ProtectedRoute requiredRole="ROLE_SUPERADMIN" />,
            children: [
                {
                    path: "/hallinta/artikkelit",
                    element: (
                        <AdminLayout>
                            <Blog />
                        </AdminLayout>
                    ),
                },
                {
                    path: "/hallinta/artikkelit/:postId",
                    element: (
                        <AdminLayout>
                            <BlogEdit />
                        </AdminLayout>
                    ),
                },
                {
                    path: "/hallinta/kayttajat",
                    element: (
                        <AdminLayout>
                            <Users />
                        </AdminLayout>
                    ),
                },
                {
                    path: "/hallinta/kayttajat/:id",
                    element: (
                        <AdminLayout>
                            <User />
                        </AdminLayout>
                    ),
                },
                {
                    path: "/hallinta/kohteet",
                    element: (
                        <AdminLayout>
                            <Properties />
                        </AdminLayout>
                    ),
                },
                {
                    path: "/hallinta/kohde/uusi",
                    element: (
                        <AdminLayout>
                            <PropertyEdit />
                        </AdminLayout>
                    ),
                },
                {
                    path: "/hallinta/kohde/muokkaa/:id",
                    element: (
                        <AdminLayout>
                            <PropertyEdit />
                        </AdminLayout>
                    ),
                },
                {
                    path: "/hallinta/asetukset",
                    element: (
                        <AdminLayout>
                            <Settings />
                        </AdminLayout>
                    ),
                },
                {
                    path: "/hallinta/alennuskoodit",
                    element: (
                        <AdminLayout>
                            <DiscountCodes />
                        </AdminLayout>
                    ),
                },
                {
                    path: "/hallinta/ilmoitukset",
                    element: (
                        <AdminLayout>
                            <Auctions />
                        </AdminLayout>
                    ),
                },
                {
                    path: "/hallinta/yhteysviestit",
                    element: (
                        <AdminLayout>
                            <ContactMessagesPage />
                        </AdminLayout>
                    ),
                },
                {
                    path: "/hallinta/tuotteet",
                    element: (
                        <AdminLayout>
                            <Products />
                        </AdminLayout>
                    ),
                },
                {
                    path: "/hallinta/tarjoukset",
                    element: (
                        <AdminLayout>
                            <Offers />
                        </AdminLayout>
                    ),
                },
            ],
        },
        {
            element: <ProtectedRoute requiredRole="ROLE_GOD" />,
            children: [
                {
                    path: "/hallinta/toiminnot",
                    element: (
                        <AdminLayout>
                            <AdminActions />
                        </AdminLayout>
                    ),
                },
            ],
        },
    ];

    useEffect(() => {
        const tagManagerArgs = {
            gtmId: "AW-11328598452",
        };
        TagManager.initialize(tagManagerArgs);
    }, []);

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: "#01696e",
                    colorInfo: "#01696e",
                    colorWarning: "#dabe1f",
                    colorSuccess: "#62a93e",
                    colorLink: "#2f54eb",
                    borderRadius: 4,
                    colorError: "#e13235",
                    wireframe: false,
                },
                components: {
                    Radio: {
                        buttonSolidCheckedBg: "#00b96b",
                        buttonSolidCheckedHoverBg: "#00b96b",
                    },
                },
            }}
        >
            <ModalProvider>
                <Router>
                    <AuthProvider>
                        <ChatProvider>
                            <Layout className="bg-white font-Outfit">
                                <Routes>
                                    {routes.map((route, index) => {
                                        if (route.children) {
                                            return (
                                                <Route key={index} element={route.element}>
                                                    {route.children.map((child, index) => (
                                                        <Route key={index} path={child.path} element={child.element} />
                                                    ))}
                                                </Route>
                                            );
                                        } else {
                                            return <Route key={index} path={route.path} element={route.element} />;
                                        }
                                    })}
                                </Routes>
                            </Layout>
                        </ChatProvider>
                    </AuthProvider>
                </Router>
            </ModalProvider>
        </ConfigProvider>
    );
};

export default App;
